exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-enduro-tsx": () => import("./../../../src/pages/enduro.tsx" /* webpackChunkName: "component---src-pages-enduro-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-tsx": () => import("./../../../src/pages/layout.tsx" /* webpackChunkName: "component---src-pages-layout-tsx" */),
  "component---src-pages-navigation-tsx": () => import("./../../../src/pages/Navigation.tsx" /* webpackChunkName: "component---src-pages-navigation-tsx" */),
  "component---src-pages-splavy-company-tsx": () => import("./../../../src/pages/splavy/company.tsx" /* webpackChunkName: "component---src-pages-splavy-company-tsx" */),
  "component---src-pages-splavy-contact-us-tsx": () => import("./../../../src/pages/splavy/ContactUs.tsx" /* webpackChunkName: "component---src-pages-splavy-contact-us-tsx" */),
  "component---src-pages-splavy-footer-tsx": () => import("./../../../src/pages/splavy/Footer.tsx" /* webpackChunkName: "component---src-pages-splavy-footer-tsx" */),
  "component---src-pages-splavy-how-to-get-to-us-tsx": () => import("./../../../src/pages/splavy/HowToGetToUs.tsx" /* webpackChunkName: "component---src-pages-splavy-how-to-get-to-us-tsx" */),
  "component---src-pages-splavy-kerd-tsx": () => import("./../../../src/pages/splavy/kerd.tsx" /* webpackChunkName: "component---src-pages-splavy-kerd-tsx" */),
  "component---src-pages-splavy-pronya-tsx": () => import("./../../../src/pages/splavy/pronya.tsx" /* webpackChunkName: "component---src-pages-splavy-pronya-tsx" */),
  "component---src-pages-splavy-rent-tsx": () => import("./../../../src/pages/splavy/rent.tsx" /* webpackChunkName: "component---src-pages-splavy-rent-tsx" */),
  "component---src-pages-splavy-routes-glamping-tsx": () => import("./../../../src/pages/splavy/routes/Glamping.tsx" /* webpackChunkName: "component---src-pages-splavy-routes-glamping-tsx" */),
  "component---src-pages-splavy-routes-one-day-tsx": () => import("./../../../src/pages/splavy/routes/OneDay.tsx" /* webpackChunkName: "component---src-pages-splavy-routes-one-day-tsx" */),
  "component---src-pages-splavy-routes-three-days-tsx": () => import("./../../../src/pages/splavy/routes/ThreeDays.tsx" /* webpackChunkName: "component---src-pages-splavy-routes-three-days-tsx" */),
  "component---src-pages-splavy-routes-two-days-tsx": () => import("./../../../src/pages/splavy/routes/TwoDays.tsx" /* webpackChunkName: "component---src-pages-splavy-routes-two-days-tsx" */),
  "component---src-pages-splavy-three-day-tsx": () => import("./../../../src/pages/splavy/three_day.tsx" /* webpackChunkName: "component---src-pages-splavy-three-day-tsx" */),
  "component---src-pages-splavy-tsx": () => import("./../../../src/pages/splavy.tsx" /* webpackChunkName: "component---src-pages-splavy-tsx" */)
}

